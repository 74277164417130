<template>
    <div>
        <component :is="!isStudent?'tenant-app-layout':'app-layout'">
            <template v-slot:header v-if="isStudent">
                <Header :title="$t('new_housing_reservation')" :is-filter="false"

                        @filter-div-status="datatable.filterStatus = $event">
                </Header>
            </template>
            <template v-slot:header-mobile v-if="isStudent">
                <HeaderMobile :title="$t('new_housing_reservation')" :is-filter="false"
                              @filter-div-status="datatable.filterStatus = $event">
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <ValidationObserver ref="formModalValidate">
                    <!-- Date Range Filter -->
                    <b-col cols="12" class="p-3">
                        <b-form-group :label="$t('start_date') + ' / ' + $t('end_date')">
                            <ValidationProvider name="date" rules="required" v-slot="{ valid, errors }">
                                <v-date-picker
                                    v-model="datatable.queryParams.filter.date"
                                    is-range
                                    :locale="'en'"
                                    :model-config="{ type: 'string', mask: 'MM/DD/YYYY' }"
                                >
                                    <template v-slot="{ inputValue, inputEvents }">
                                        <div class="border rounded-sm p-2 p-md-3 d-flex align-items-center"
                                             :class="errors[0] ? 'box-border-color' : ''">
                                            <div class="flex-grow-1">
                                                <b-form-group class="m-0 mr-2">
                                                    <input
                                                        :value="inputValue.start"
                                                        v-on="inputEvents.start"
                                                        :placeholder="$t('start_date')"
                                                        class="form-control"
                                                    />
                                                </b-form-group>
                                            </div>
                                            <div class="flex-grow-1">
                                                <b-form-group class="m-0 ml-2">
                                                    <input
                                                        :value="inputValue.end"
                                                        v-on="inputEvents.end"
                                                        :placeholder="$t('end_date')"
                                                        class="form-control"
                                                    />
                                                </b-form-group>
                                            </div>
                                        </div>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </template>
                                </v-date-picker>
                            </ValidationProvider>
                        </b-form-group>
                    </b-col>

                    <!-- Room Type Filter -->
                    <b-col cols="12">
                        <ValidationProvider name="room_type" rules="" v-slot="{ valid, errors }">
                            <b-form-group :label="$t('room_type')">
                                <parameter-selectbox :public="true" code="housing_room_types" v-model="datatable.queryParams.filter.room_type" :validate-error="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col cols="12">
                        <ValidationProvider name="type" rules="" v-slot="{ valid, errors }">
                            <b-form-group :label="$t('bed_type')">
                                <parameter-selectbox :public="true" code="housing_bed_types" v-model="datatable.queryParams.filter.type" :validate-error="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                </ValidationObserver>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                       :queryParams="datatable.queryParams" :lineNumbers="false"
                       @on-page-change="onPageChange" @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable">
            </datatable>

        </component>
    </div>
</template>
<script>
//Template
import AppLayout from "@/layouts/AppLayout.vue";
import Header from "@/layouts/AppLayout/Header.vue"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile.vue"
//Component
import DatatableFilter from "@/components/datatable/DatatableFilter.vue";
import Datatable from "@/components/datatable/Datatable.vue";

//Other
import qs from "qs";
//Service
import AvailabilityOfRoomServices from "@/services/AvailabilityOfRoomServices";
import AddReservationForm from "@/modules/AvailabilityOfRooms/pages/AddReservationForm.vue";
import GenderSelectbox from "@/components/interactive-fields/GenderSelectbox.vue";
import AddReservationStudentForm from "@/modules/AvailabilityOfRooms/pages/AddReservationStudentForm.vue";
import YesNoSelectbox from "@/components/interactive-fields/YesNoSelectbox.vue";
import TenantAppLayout from "@/layouts/TenantAppLayout.vue";
import moment from "moment";
import HousingReservationServices from "@/services/HousingReservationServices";
//Page


export default {
    components: {
        YesNoSelectbox,
        AddReservationStudentForm,
        GenderSelectbox,
        AddReservationForm,
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        TenantAppLayout
    },
    metaInfo() {
        return {
            title: this.$t("new_housing_reservation"),
        };
    },

    data() {
        return {
            formData: {},
            form: {},
            formProcess: null,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t('create_reservation_request'),
                                class: 'ri-add-line',
                                callback: (row) => {
                                    this.createReservationRequest(row.id);
                                },

                            },
                        ],
                    },
                    {
                        label: this.$t('building'),
                        field: 'housing_building_name',
                        sortable: true
                    },
                    {
                        label: this.$t('room'),
                        field: 'housing_room_name',
                        sortable: true
                    },
                    {
                        label: this.$t('floor'),
                        field: 'housing_floor',
                        sortable: true
                    },
                    {
                        label: this.$t('room_type'),
                        field: 'room_type_name',
                        sortable: true
                    },
                    {
                        label: this.$t('bed_type'),
                        field: 'type_name',
                        sortable: true
                    },
                    {
                        label: this.$t('bed'),
                        field: 'bed_name',
                        sortable: true
                    },
                    {
                        label: this.$t('monthly_fee'),
                        field: 'fee',
                        sortable: true,
                        formatFn: (row) => {
                            return  this.currencyFormat(row)
                        }
                    },
                    {
                        label: this.$t('total_amount'),
                        field: 'total_amount',
                        sortable: true,
                        formatFn: (row) => {
                            return  this.currencyFormat(row)
                        }
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },
        };
    },
    computed:{
        user() {
            return this.$store.getters["auth/getUser"];
        },
        isStudent() {
            return this.$store.getters["auth/isStudent"];
        },
        isTenant() {
            return !!localStorage.getItem("tenant_user")
        },
    },
    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        async getRows() {
            let isValid = await this.$refs.formModalValidate.validate()
            if (!isValid) {
                return
            }
            this.datatable.showTable = true;
            this.datatable.isLoading = true;
            if (this.datatable.queryParams.filter.date.start && this.datatable.queryParams.filter.date.end) {

                this.datatable.queryParams.filter.check_in_date =this.datatable.queryParams.filter.date.start

                this.datatable.queryParams.filter.check_out_date = this.datatable.queryParams.filter.date.end
            }
            const config = {
                params: {
                    ...this.datatable.queryParams,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };

            return AvailabilityOfRoomServices.getAllForStudent(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        async createReservationRequest(id) {
            this.$swal
                .fire({
                    text: "Are you sure you want to create a reservation request?",
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        try {
                            this.formData.housing_bed_id = id;
                            this.formData.check_in_date = this.datatable.queryParams.filter.check_in_date;
                            this.formData.check_out_date = this.datatable.queryParams.filter.check_out_date;
                            HousingReservationServices.reservationStudent(this.formData).then(response=>{
                                this.$toast.success(this.$t('api.' + response.data.message));
                                this.$router.push('/my-housing-reservations');
                            })
                        } catch (error) {
                            this.showErrors(error);
                        }
                    }
                })
        },

    },
};
</script>

